<template>
  <Header />
  <marquee style="padding-top: 5px;color: #036a3b;" width = "100%"  v-for="(value, key, index) in TextRun" :key="index">
    {{ value.title }}
  </marquee>
  <router-view/>
  <Footer />
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
const axios = require("axios");
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      TextRun:[],
    }
  },
  mounted(){
      this.GetTextRun();
  },
  methods: {
    GetTextRun(){
      axios.get("http://admin.phone.2m-sport.com/api/message").then((res)=>{
        this.TextRun = res.data.data;
      }).catch(function (error) {
          console.error(error);
      });
    },
  },
}
</script>
