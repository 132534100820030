<script>
const axios = require("axios");
import Header_Top from '../components/Header_Top.vue'
if (localStorage.lang) {
    localStorage.lang;
} else {
    localStorage.lang = 'kh';
}
export default {
    name: 'HeaDer',
    components: {
        Header_Top
    },
    data() {
        return {
            lang: localStorage.lang,
            hover: true,
            Category:[],
        }
    },
    mounted(){
      this.GetCategory();
    },
    methods: {
        GetCategory(){
        axios.get("http://admin.phone.2m-sport.com/api/category").then((res)=>{
            this.Category = res.data.data;
        }).catch(function (error) {
            console.error('false');
        });
        },
        categoryclick(data){
        this.$router.push({
            path: '/ourstore',
            param:data,
            query: {
            id: data,
            type: 'category'
            }
        });
        },
    },

}
</script>
<template>
<div class="green">
    <div class="container-xxl">
        <Header_Top />
        <header class="px-0 v-toolbar v-toolbar--flat sila" style="padding-top:10px;padding-bottom:10px">
            <span class="sila">
                <router-link to="/" class="nav-link-example p-r-5px">
                    <button type="button" class="button btn btn-lg nav__item">
                        <span class="v-btn__content">
                            <P v-if="lang == 'kh'" style="font-size: 17px;">ទំព័រដើម</P>
                            <P v-if="lang == 'en'" style="font-size: 17px;">HOME</P>
                        </span>
                    </button>
                </router-link>

                <router-link to="/ourstore" class="nav-link-example p-r-5px">
                    <button type="button" class="button btn btn-lg nav__item">
                        <span class="v-btn__content">
                            <P v-if="lang == 'kh'" style="font-size: 17px;">បញ្ជីផលិតផល</P>
                            <P v-if="lang == 'en'" style="font-size: 17px;">OUR STORE</P>
                        </span>
                    </button>
                </router-link>

                <router-link to="/promotions" class="nav-link-example p-r-5px">
                    <button type="button" class="button btn btn-lg nav__item">
                        <span class="v-btn__content">
                            <P v-if="lang == 'kh'" style="font-size: 17px;">ប្រម៉ូសិន</P>
                            <P v-if="lang == 'en'" style="font-size: 17px;">PROMOTIONS</P>
                        </span>
                    </button>
                </router-link>
                <router-link to="/applyloan" class="nav-link-example p-r-5px">
                    <button type="button" class="button btn btn-lg nav__item">
                        <span class="v-btn__content">
                            <P v-if="lang == 'kh'" style="font-size: 17px;">ស្នើសុំបង់រំលោះ</P>
                            <P v-if="lang == 'en'" style="font-size: 17px;">APPLY LOAN</P>
                        </span>
                    </button>
                </router-link>
                <router-link to="/aboutus" class="nav-link-example p-r-5px">
                    <button type="button" class="button btn btn-lg nav__item">
                        <span class="v-btn__content">
                            <P v-if="lang == 'kh'" style="font-size: 17px;">អំពីយើង</P>
                            <P v-if="lang == 'en'" style="font-size: 17px;">ABOUT US</P>
                        </span>
                    </button>
                </router-link>
            </span>
        </header>
    </div>
    <div id="popup_footer">
        <div class="footer-contact">
            <div class="grid-container">
                <!-- <div class="grid-item bounce-1 " v-for="(value, key, index) in Category.slice(0,3)" :key="index">
                    <a @click="categoryclick(value.id)">
                        <img :src="'http://admin.phone.2m-sport.com/upload/'+value.photo1" alt="brand"/>
                        <br><text>{{ value.name }}</text>
                    </a>
                </div> -->
                <div class="grid-item bounce-1 ">
                    <a href="tel:081354789">
                        <img src="/upload/phone.png" alt="brand"/>
                        <br><text>Phone</text>
                    </a>
                </div>
                <div class="grid-item bounce-1 ">
                    <a href="https://www.facebook.com/presidentphoneshop">
                        <img src="/upload/facebook.png" alt="brand"/>
                        <br><text>Facebook</text>
                    </a>
                </div>
                <div class="grid-item bounce-1">
                    <a href="https://t.me/psphoneshop">
                        <img src="/upload/telegram.png" alt="brand"/>
                        <br><text>Telegram</text>
                    </a>
                </div>
                <div class="grid-item bounce-1">
                    <a href="https://www.tiktok.com/@presidentphoneshop">
                        <img src="/upload/tiktok.png" alt="brand"/>
                        <br><text>Tiktok</text>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<style scoped>

    .bounce-1 {
        animation-name: unset;
        animation-timing-function: linear;
    }

    @keyframes bounce-1 {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-10px);
        }

        100% {
            transform: translateY(0);
        }
    }
    .grid-item {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        margin: 0 auto 0 auto;
        transform-origin: bottom;
    }
    .bounce-1:hover {
        animation-name: bounce-1 !important;
    }

    .grid-item img:hover {
    filter: grayscale(0);
    }
    .grid-item:hover text{ 
        color: green;
    }
    #popup_footer{
        display: none;
    }
@media (max-width: 1000px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 45px !important;
        width: 45px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: unset !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 15px;
        color: black;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding: 20px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding: 10px;
        font-size: 10px;
        text-align: center;
    }
  }

@media (max-width: 800px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 40px !important;
        width: 40px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 12px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding: 10px;
        text-align: center;
    }
  }
@media (max-width: 700px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 35px !important;
        width: 35px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: unset !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 11px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding: 10px;
        font-size: 10px;
        text-align: center;
    }
  }
@media (max-width: 600px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 35px !important;
        width: 35px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: 10px !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 10px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding-left: 10px;
        padding-right: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        text-align: center;
    }
  }
@media (max-width: 500px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 30px !important;
        width: 30px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: unset !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 9px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding-left: 10px;
        padding-right: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        text-align: center;
    }
  }
@media (max-width: 400px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 25px !important;
        width: 25px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: unset !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 7px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding-left: 10px;
        padding-right: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        text-align: center;
    }
  }
  @media (max-width: 300px) {
    #popup_footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid-item img {
        height: 25px !important;
        width: 25px !important;
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: -2%;
        z-index: 1000;
        padding: unset !important;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    text{
        font-size: 7px;
        color: rgb(0, 0, 0);
    }
    #popup_footer .footer-contact {
        position: fixed;
        bottom: 5%;
        z-index: 1000;
        padding-left: 10px;
        padding-right: 10px;
        backdrop-filter: blur(10px);
        box-shadow: #00000030 0px 3px 20px -1px;
        border-radius: 10px;
    }
    .grid-item {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        text-align: center;
    }
  }
  

    .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .footer-contact {
        mix-width: 100%;
    }

.p-r-5px {
    padding-right: 5px;
}

.btn {
    --bs-btn-border-color: user-select: none;
    ;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

.green {
    background-color: #046A3A;
}

.button {
    box-shadow: unset;
}

.button:hover {
    border-radius: 5px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    transition: all .3s ease-in-out !important;
    background: radial-gradient(50.43% 23.75% at 50.43% 0, #65ffc4 0, hsla(0, 0%, 100%, 0) 100%), radial-gradient(65% 65% at 50.43% 0, #3a7266ba 0, rgba(255, 225, 150, 0) 100%), linear-gradient(180deg, #43ec78, #2bbd6fba 99.48%) !important;
}

.v-btn__content {
    font-family: 'Roboto', 'Battambang' !important;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
}

.sila {
    overflow-x: auto;
    white-space: nowrap;
}

.sila::-webkit-scrollbar {
    display: none;
}

a.router-link-exact-active {
    color: #fff;
    border-radius: 2px;
}

a.router-link-exact-active .button {
    border-radius: 5px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    transition: all .3s ease-in-out !important;
    background: radial-gradient(50.43% 23.75% at 50.43% 0, #65ffc4 0, hsla(0, 0%, 100%, 0) 100%), radial-gradient(65% 65% at 50.43% 0, #3a7266ba 0, rgba(255, 225, 150, 0) 100%), linear-gradient(180deg, #43ec78, #2bbd6fba 99.48%) !important;
}


</style>
